<template>
    <div class="success">
  <van-nav-bar
  title="提交成功"
  left-arrow
  @click-left="$router.back()"
/>
  <div class="content">
      <div><img style="width:70px;height:70px" src="../assets/images/ok.png" alt=""></div>
      <div class="order_over">完成订票</div>
      <div>请耐心等待工作人员与您联系</div>
  </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{

            }
        },
        methods:{
            onClickLeft(){}
        }
    }
</script>

<style lang="scss" scoped>
.success{
    width:100%;
    height:100%;
    .content{
        text-align: center;
        margin-top:30%;
        line-height:30px;
        .order_over{
            font-size: 16px;
        }
    }
}
</style>